import React from "react";
import Accueil from "./components/accueil/index";

function App() {
  return (
    <div className="App">
      <Accueil />
    </div>
  );
}

export default App;

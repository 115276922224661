import React, { useEffect, useState } from "react";
// import Virus from "../virus/index";
import './index.css'
import VirusImg from '../../img/virus.png'

const Accueil = () => {
    const [partie, setPartie] = useState(true)
    
    const [top, setTop] = useState()
    const [bottom, setBottom] = useState()
    const [left, setLeft] = useState()
    const [right, setRight] = useState()

    const initialArray = []

    const [createVirus, setCreateVirus] = useState('')

    useEffect(() => {
        initialArray.push(...createVirus, <img className={"absolute virusMoove un"} style={{top:'50%', left:'50%'}} key={Math.random() * 100} src={VirusImg} />)
    }, [])

    useEffect(() => {
        if(partie === true) {
            const a = setInterval(() => {
                var animation = Math.floor(Math.random() * (4 - 1 + 1)) + 1
                switch (animation) {
                    case 1:
                        animation = "un"
                        break

                    case 2:
                        animation = "deux"
                        break

                    case 3:
                        animation = "trois"
                        break

                    case 4:
                        animation = "quatre"
                        break
                }

                setTop(Math.floor(Math.random() * 95) + '%')
                setBottom(Math.floor(Math.random() * 95) + '%')
                setLeft(Math.floor(Math.random() * 95) + '%')
                setRight(Math.floor(Math.random() * 95) + '%')
                initialArray.push(...createVirus, <img className={"absolute virusMoove " + animation} key={Math.random() * 100} 
                style={{top: top, bottom: bottom, left: left, right: right}} src={VirusImg} />)
                setCreateVirus(initialArray)
            }, Math.floor(Math.random() * 650))
            return () => clearInterval(a)
        } 
    }, [initialArray])

    const [badClick, setBadClick] = useState(0)

    const clickMap = () => {
        setBadClick(badClick + 1)
    }

    const [time, setTime] = useState(30)

    useEffect(() => {
        const e = setInterval(() => {
            setTime(time - 1)
        },1000)
        return () => clearInterval(e)
    }, [time])

    const [pts, setPts] = useState(0)

    const cliqueVirus = (e) => {
      setPts(pts + 1)
      e.target.src = ""
    }

    const newPartie = () => {
        setTime(30)
        setPts(0)
        setBadClick(0)
        setPartie(true)
    }

    useEffect(() => {
        if(time === 0) {
            setPartie(false)
            setCreateVirus('')
        }    
    }, [time])

    return (
        <>
        <div className="moyenFormulaire">
            <div className="title center">Virusland</div><br />
            <div className="sousTitle center">Aidez le professeur Raoult à sauver le monde en éliminant les virus <img src={VirusImg} width="28px" />  ! </div><br />
            {time > 0 ? (
            <>
            <div className="flex oppose bc-corail pad8-18 borderCorail ">
                <div className="width100 center texte black"> Score : {pts} </div>
                <div className="width100 center texte black">{time}</div>
            </div>
            <div className="jeuxCarre relative borderCorailRadius" onClick={() => clickMap()}>
                <div onClick={(e) => cliqueVirus(e)}>{createVirus}</div>
            </div>
            </>
            ) : (
            <>
            <div className="bc-corail pad30">
                <div className="title center ">La partie est fini</div><br /><br />
                <div className="title bleu pad8-18 auto width40 center radius5">{pts} points</div><br /><br />
                <div className="title auto width40 center radius5">{
                isNaN(Math.floor(pts / badClick * 100)) === true ? (
                    <div className="erreur pad8-18 radius5">0 % précision</div>
                ) : (Math.floor(pts /badClick * 100)) > 50 ? (
                    <div className="good pad8-18 radius5">{Math.floor(pts / badClick * 100)} % précision</div>
                 ) : (
                    <div className="erreur pad8-18 radius5">{Math.floor(pts / badClick * 100)} % précision</div>
                 )}
                 <br />
                 <div className="goodOrange pad8-18 radius5 hover" onClick={newPartie}>Nouvelle partie</div>
                </div>
            </div>
            </>
            )}
        </div>
        </>
    )
}

export default Accueil